import React, { useEffect } from 'react';
import Header from '../components/Header';
import $ from "jquery";
import party from "party-js";

const TeethGame: any = {};

function Teeth() {
  useEffect(() => {
    document.querySelector("#root")!.classList.add('game-truefalse');
    TeethGame.init();

    return () => {
        document.querySelector("#root")!.classList.remove('game-truefalse');
        TeethGame.dispose();
    };
  }, []);

  return <>
    <Header active="match" />
    
    <div id="board-wrapper">
        <div id="board"></div>
    </div>
  </>
}

TeethGame.init = function() {
  TeethGame.game = null;
  TeethGame.playing = false;
  TeethGame.imgOrder = [];
  TeethGame.counter = 0;
  TeethGame.correctPosInterval = null;
  TeethGame.currentRes = null;
  TeethGame.blocked = false;

  TeethGame.beforeUnload = function(e: any) {
    if (TeethGame.playing) {
        e.preventDefault();
        return e.returnValue = " ";
    }
  }

  TeethGame.handleContextMenu = function(e: any) {
    e.preventDefault();
    return false;
  }

  window.addEventListener("beforeunload", TeethGame.beforeUnload, false);
  document.addEventListener("contextmenu", TeethGame.handleContextMenu);

  TeethGame.correctPosInterval = setInterval(function() {
	TeethGame.correctPos();
  }, 500);
  
  TeethGame.start(TeethGame.games[0]);
}

TeethGame.dispose = function() {
  $("body").css("background-color", "white");

  window.removeEventListener("beforeunload", TeethGame.beforeUnload, false);
  document.removeEventListener("contextmenu", TeethGame.handleContextMenu);
  
  if (TeethGame.correctPosInterval != null) {
	clearInterval(TeethGame.correctPosInterval);
	TeethGame.correctPosInterval = null;
  }
}

TeethGame.correctPos = function() {
	const ci = $("#board #current-image")[0];
	const ciRect = ci.getBoundingClientRect();
	const gap = ciRect.height * 0.15;
	const hgap = ciRect.width * 0.1;
	
	const trueI = $("#board #img-true");
	trueI.css({"width": (ciRect.width * 0.2) + "px", "height": (ciRect.width * 0.2) + "px"})
	const trueIRect = trueI[0].getBoundingClientRect();
	trueI.css({"left": (ciRect.width - trueIRect.width - hgap), "top": gap + "px"});
	
	const falseI = $("#board #img-false");
	falseI.css({"width": (ciRect.width * 0.2) + "px", "height": (ciRect.width * 0.2) + "px"})
	const falseIRect = falseI[0].getBoundingClientRect();
	falseI.css({"left": (ciRect.width - falseIRect.width - hgap), "top": ((2 * gap) + falseIRect.height) + "px"});
	
	const wrongI = $("#board #img-wrong");
	const wrongIRect = wrongI[0].getBoundingClientRect();
	wrongI.css({"left": (ciRect.width / 2 - wrongIRect.width / 2), "top": (ciRect.height / 2 - wrongIRect.height / 2) + "px"});
};

TeethGame.games = [
  {
    name: 'Zdravé Zuby',
    base: '/images/truefalse_teeth/',
    images: [{"1.jpg": 1}, {"2.jpg": 0}, {"3.jpg": 1}, {"4.jpg": 0}, {"5.jpg": 1}, {"6.jpg": 1}, {"7.jpg": 1}, {"8.jpg": 1}, {"9.jpg": 0}, {"10.jpg": 0}, 
		{"11.jpg": 0}, {"12.jpg": 1}, {"13.jpg": 1}, {"14.jpg": 0}, {"15.jpg": 0}, {"16.jpg": 1}, {"17.jpg": 0}, {"18.jpg": 0}, {"19.jpg": 1}, {"20.jpg" : 0},
		{"21.jpg": 0}, {"22.jpg" : 1}, {"23.jpg": 0}, {"24.jpg" : 1}, {"25.jpg": 1}],
    baseColor: 'bb7d05'
  }
];

TeethGame.start = function(game: any) {
  TeethGame.game = game;
  TeethGame.playing = true;
  TeethGame.counter = 0;

  $("#board-wrapper").css("display", "flex");
  $("#board").html("<img id='current-image' /><img id='img-true' /><img id='img-false' /><img id='img-wrong' />");
  
  $("#board #img-true").on("click", function() {
	if (TeethGame.blocked) {
		return;
	}
	if (TeethGame.currentRes === 1) {
		TeethGame.blocked = true;
		party.sparkles($("#board")[0], {count: party.variation.range(100, 50), lifetime: 3});
		setTimeout(function() {
			TeethGame.blocked = false;
			TeethGame.nextImage();
		}, 3000);
	} else {
		$("#board #img-wrong").show();
		TeethGame.correctPos();
		$("#board #img-wrong").fadeOut(2000);
	}
  });
  $("#board #img-false").on("click", function() {
	if (TeethGame.blocked) {
		return;
	}
	if (TeethGame.currentRes === 0) {
		TeethGame.blocked = true;
		party.sparkles($("#board")[0], {count: party.variation.range(100, 50), lifetime: 3});
		setTimeout(function() {
			TeethGame.blocked = false;
			TeethGame.nextImage();
		}, 3000);
	} else {
		$("#board #img-wrong").show();
		TeethGame.correctPos();
		$("#board #img-wrong").fadeOut(2000);
	}
  });

  TeethGame.imgOrder = [...Array(game.images.length).keys()];
  TeethGame.shuffle();

  $("#board #img-true").attr("src", TeethGame.game.base + "true.png");
  $("#board #img-false").attr("src", TeethGame.game.base + "false.png");
  $("#board #img-wrong").attr("src", TeethGame.game.base + "wrong.png");
	
  TeethGame.nextImage();
}

TeethGame.nextImage = function() {
	if (TeethGame.counter > TeethGame.game.images.length) {
		$("#board").empty();
		return;
	}
	const imgIdx = TeethGame.imgOrder[TeethGame.counter++];
	const imgObj = Object.entries(TeethGame.game.images[imgIdx])[0];
	TeethGame.currentRes = imgObj[1];
    $("#board #current-image").attr("src", TeethGame.game.base + imgObj[0]);
	TeethGame.correctPos();
}

TeethGame.shuffle = function() {
  let currentIndex = TeethGame.imgOrder.length, randomIndex;
  while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [TeethGame.imgOrder[currentIndex], TeethGame.imgOrder[randomIndex]] = [TeethGame.imgOrder[randomIndex], TeethGame.imgOrder[currentIndex]];
  }
}

export default Teeth;