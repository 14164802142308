import React, { useContext, useEffect, useRef, useState } from 'react';
import Header from '../components/Header';
import { UserContext, PupilInfo, FolderType } from '../App';
import { config } from '../util/constants';

const baseUrl = config.API_URL;

function MyClass() {
	const userCtx = useContext(UserContext);
	const [pupilList, setPupilList] = useState<PupilInfo[] | null>(null);
	const [editPupil, setEditPupil] = useState<PupilInfo | null>(null);

	const [excluded, setExcluded] = useState(new Set<number>());
	const [selected, setSelected] = useState<string | null>(null);
	const [tab, setTab] = useState("evaluation");
	const [shuffled, setShuffled] = useState<string[]>([]);

	const [toastText, setToastText] = useState<string | null>(null);
	const timeoutRef = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		if (isAuthenticated()) {
			loadData();
		}
	}, [userCtx]);

	const isAuthenticated = () => {
		return userCtx.user != null;
	};
	
	const loadData = () => {
		fetch(baseUrl + '/downloadData/privateFolder/myclass/class1A.json', {
			credentials: 'include'
		}).then((res: Response) => {
			if (res.ok) {
				res.json().then((json) => {
					setPupilList(json);
				});
			} else {
				throw new Error(`Server error: ${res.status} ${res.statusText}`);
			}
		}).catch((e: Error) => {
			console.error("Failed to load data!", e);
		});
	};
	
	const saveData = (pupilList: PupilInfo[]) => {
		showToast("Ukladám...");
		const fileToUpload = new File([JSON.stringify(pupilList)], 'class1A.json', { type: "application/json" });
		
		const formData  = new FormData();
	    formData.append("folderType", FolderType.private);
	    formData.append("folder", 'myclass');
	    formData.append("file", fileToUpload);
		
		fetch(baseUrl + '/uploadData', {
			credentials: 'include',
	        method: "POST",
	        body: formData
	    }).then((res: Response) => {
			if (res.ok) {
				showToast("Uložené!");
				loadData();
	        } else {
				throw new Error(`Server error: ${res.status} ${res.statusText}`);
			}
		}).catch((e: Error) => {
			console.error("Failed to upload data!", e);
			showToast("Chyba pri ukladaní!");
		});
	}
	
	const getBadgeNumber = (index: number) => {
		if (editPupil == null || editPupil.badges == null || editPupil.badges[index + ""] == null) {
			return 0;
		}
		return editPupil.badges[index + ""];
	}

	const setPupilBadge = (index: number) => {
		setEditPupil(prevPupil => {
			if (prevPupil == null) return null;
	
			const newBadges = { ...(prevPupil.badges || {}) };
			const key = index.toString();
			newBadges[key] = (newBadges[key] || 0) === 3 ? 0 : (newBadges[key] || 0) + 1;
	
			const updatedPupil = { ...prevPupil, badges: newBadges };
	
			setPupilList(prevList => {
				if (prevList == null) {
					return null;
				}
				const newList = prevList.map(pupil =>
					pupil.id === updatedPupil.id ? updatedPupil : pupil
				)
				saveData(newList);
				return newList;
			});
	
			return updatedPupil;
		});
	}

	const showToast = (text: string) => {
		if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
		setToastText(text);
		timeoutRef.current = setTimeout(() => {
			timeoutRef.current = null;
			setToastText(null);
        }, 5000);
	}

	const getTotalNumber = () => {
		if (editPupil == null || editPupil.badges == null) {
			return 0;
		}
		return Object.entries(editPupil.badges).reduce((sum, [key, value]) => {
			const index = parseInt(key, 10);
			return index >= 29 ? sum - value : sum + value;
		}, 0);
	}

	const generateRandom = () => {
		let possiblePupils = pupilList!.filter(pupil => !excluded.has(pupil.id) && pupil.picked !== true);
		if (possiblePupils.length === 0) {
			setSelected("Nie je možné vybrať žiaka.");
			return;
		}
		const randomIndex = Math.floor(Math.random() * possiblePupils.length);
		setSelected(possiblePupils[randomIndex].name);
		const selectedId = possiblePupils[randomIndex].id;

		setPupilList(prevList => {
			if (prevList == null) {
				return null;
			}
			const newList = prevList.map(pupil => {
				let mappedPupil = pupil;
				if (pupil.id === selectedId) {
					mappedPupil = { ...pupil, picked: true };
				}
				return mappedPupil;
			});
			saveData(newList);
			return newList;
		});
	};

	const resetPicked = () => {
		setPupilList(prevList => {
			if (prevList == null) {
				return null;
			}
			const newList = prevList.map(pupil => {
				return { ...pupil, picked: false };
			});
			saveData(newList);
			return newList;
		});
	};

	const generateShuffled = () => {
		let possiblePupils = pupilList!.filter(pupil => !excluded.has(pupil.id)).map(pupil => pupil.name);
		for (let i = possiblePupils.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[possiblePupils[i], possiblePupils[j]] = [possiblePupils[j], possiblePupils[i]];
		}
		setShuffled(possiblePupils);
	};

	const toggleExclusion = (id: number) => {
		setExcluded((prev) => {
			const newSet = new Set(prev);
			if (newSet.has(id)) {
				newSet.delete(id);
			} else {
				newSet.add(id);
			}
			return newSet;
		});
	};
	
	return <>
		<Header active="eval" />

		{ isAuthenticated() && pupilList != null && <>
			<div className="flex flex-col justify-center min-h-0 h-max">
				<div className={`toast-msg ${toastText == null ? 'hidden' : ''}`}>{ toastText }</div>

				<div className="sub-tabs flex space-x-4 px-6">
					<button onClick={() => { setTab("evaluation"); setEditPupil(null); } } className={`${tab === "evaluation" ? "font-bold" : ""} sub-tab`}>Hodnotenie</button>
					<button onClick={() => { setTab("random"); setEditPupil(null); } } className={`${tab === "random" ? "font-bold" : ""} sub-tab`}>Náhodné meno</button>
					<button onClick={() => { setTab("shuffle"); setEditPupil(null); } } className={`${tab === "shuffle" ? "font-bold" : ""} sub-tab`}>Náhodné poradie</button>
				</div>

				{tab === "evaluation" ? ( <>
					{ editPupil == null && <>
						<div className="p-6 overflow-auto" style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '50px' }}>
							{Array.from({ length: 9 }).map((_, cellIndex) => {
								const firstIndex = cellIndex * 2;
								const secondIndex = firstIndex + 1;
								const pupil1 = pupilList[firstIndex] || null;
								const pupil2 = pupilList[secondIndex] || null;
	
								return (
									<div key={cellIndex} className="flex justify-center" style={{ paddingTop: '50px', position: 'relative' }}>
										<img src="/images/evaluation/lavica.png" />
										<div style={{ position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', gap: '10px', textAlign: 'center', width: '100%', fontSize: '28px', fontWeight: 'bold' }}>
											{ pupil1 != null && 
												<div onClick={ () => setEditPupil(pupil1) } className="hover:text-[#fbbf24]" style={{ cursor: 'pointer' }}>{ pupil1.name }</div>
											}
											{ pupil1 != null && pupil2 != null && 
												<div>|</div>
											}
											{ pupil2 != null &&
												<div onClick={ () => setEditPupil(pupil2) } className="hover:text-[#fbbf24]" style={{ cursor: 'pointer' }}>{ pupil2.name }</div>
											}
										</div>
									</div>
								);
							})}
						</div>
					</> }
					{ editPupil != null && <>
						<div className="p-4 flex flex-col flex-1 min-h-0" style={{ position: 'relative', gap: '10px' }}>
							<div className="flex flex-row" style={{ justifyContent: 'space-between' }}>
								<div onClick={ () => setEditPupil(null) } style={{ cursor: 'pointer', fontSize: '30px', fontWeight: 'bold' }}>
									&larr;
								</div>
								<div style={{ fontSize: '30px', fontWeight: 'bold', width: '100%', textAlign: 'center' }}>
									{ editPupil.name }
								</div>
								<div style={{ fontSize: '30px', fontWeight: 'bold' }}>
									{ getTotalNumber() }
								</div>
							</div>
							<div className="flex flex-col flex-1" style={{ overflow: 'auto', gap: '20px', userSelect: 'none' }}>
								<div className="flex-1" style={{ display: 'flex', gap: '10px' }}>
									{Array.from({ length: 6 }).map((_, index) => {
										const idx = index;
										const num = getBadgeNumber(idx);
										return (
											<div key={index} className="flex justify-center" style={{ position: 'relative', height: '100%', width: '100%' }}>
												<img src={`/images/evaluation/badges/${idx + 1}.jpg` } style={{ objectFit: 'contain', height: '100%', width: '100%' }} />
												<div onClick={() => setPupilBadge(idx) } className={`circle-number circle-num-${num}`}>{ num }</div>
											</div>
										);
									})}
								</div>
								<div className="flex-1" style={{ display: 'flex', gap: '10px' }}>
									{Array.from({ length: 6 }).map((_, index) => {
										const idx = index + 6;
										const num = getBadgeNumber(idx);
										return (
											<div key={idx} className="flex justify-center" style={{ position: 'relative', height: '100%', width: '100%' }}>
												<img src={`/images/evaluation/badges/${idx + 1}.jpg` } style={{ objectFit: 'contain', height: '100%', width: '100%' }} />
												<div onClick={() => setPupilBadge(idx) } className={`circle-number circle-num-${num}`}>{ num }</div>
											</div>
										);
									})}
								</div>
								<div className="flex-1" style={{ display: 'flex', gap: '10px' }}>
									{Array.from({ length: 6 }).map((_, index) => {
										const idx = index + 6 + 6;
										const num = getBadgeNumber(idx);
										return (
											<div key={idx} className="flex justify-center" style={{ position: 'relative', height: '100%', width: '100%' }}>
												<img src={`/images/evaluation/badges/${idx + 1}.jpg` } style={{ objectFit: 'contain', height: '100%', width: '100%' }} />
												<div onClick={() => setPupilBadge(idx) } className={`circle-number circle-num-${num}`}>{ num }</div>
											</div>
										);
									})}
								</div>
								<div className="flex-1" style={{ display: 'flex', gap: '10px' }}>
									{Array.from({ length: 6 }).map((_, index) => {
										const idx = index + 6 + 6 + 6;
										const num = getBadgeNumber(idx);
										return (
											<div key={idx} className="flex justify-center" style={{ position: 'relative', height: '100%', width: '100%' }}>
												<img src={`/images/evaluation/badges/${idx + 1}.jpg` } style={{ objectFit: 'contain', height: '100%', width: '100%' }} />
												<div onClick={() => setPupilBadge(idx) } className={`circle-number circle-num-${num}`}>{ num }</div>
											</div>
										);
									})}
								</div>
								<div className="flex-1" style={{ display: 'flex', gap: '10px' }}>
									{Array.from({ length: 6 }).map((_, index) => {
										const idx = index + 6 + 6 + 6 + 6;
										const num = getBadgeNumber(idx);
										const isBad = idx + 1 >= 30;
										return (
											<div key={idx} className="flex justify-center" style={{ position: 'relative', height: '100%', width: '100%' }}>
												<img src={`/images/evaluation/badges/${idx + 1}.jpg` } style={{ objectFit: 'contain', height: '100%', width: '100%' }} />
												<div onClick={() => setPupilBadge(idx) } className={`circle-number circle-num-${num} ${isBad ? 'bad' : ''}`}>{ num }</div>
											</div>
										);
									})}
								</div>
								<div className="flex-1" style={{ display: 'flex', gap: '10px' }}>
									{Array.from({ length: 6 }).map((_, index) => {
										const idx = index + 6 + 6 + 6 + 6 + 6;
										const num = getBadgeNumber(idx);
										const isBad = idx + 1 >= 30;
										return (
											<div key={idx} className="flex justify-center" style={{ position: 'relative', height: '100%', width: '100%' }}>
												<img src={`/images/evaluation/badges/${idx + 1}.jpg` } style={{ objectFit: 'contain', height: '100%', width: '100%' }} />
												<div onClick={() => setPupilBadge(idx) } className={`circle-number circle-num-${num} ${isBad ? 'bad' : ''}`}>{ num }</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</> }
				</> ) : tab === "random" ? ( <>
					<div className="p-6">
						{pupilList.map((pupil) => (
							<span key={pupil.id} onClick={() => toggleExclusion(pupil.id)} className={`cursor-pointer text-lg px-2 ${pupil.picked ? 'line-through' : excluded.has(pupil.id) ? 'text-gray-400' : 'text-black'}`}>{pupil.name}</span>
						)).reduce((prev, curr) => (<>{prev} {curr}</>))}
					</div>
					<div className="text-center">
						<button onClick={generateRandom} className="btn-primary p-4 text-xl">Vyber náhodne</button>
						<button onClick={resetPicked} className="btn p-4 text-xl">Reset</button>
					</div>
					<div className="text-5xl font-bold mt-10 text-center">{selected}</div>
				</>	) : ( <>
					<div className="p-6">
						{pupilList.map((pupil) => (
							<span key={pupil.id} onClick={() => toggleExclusion(pupil.id)} className={`cursor-pointer text-lg px-2 ${excluded.has(pupil.id) ? 'text-gray-400' : 'text-black'}`}>{pupil.name}</span>
						)).reduce((prev, curr) => (<>{prev} {curr}</>))}
					</div>
					<div className="text-center">
						<button onClick={generateShuffled} className="btn-primary p-4 text-xl">Zoraď náhodne</button>
					</div>
					<div className="text-2xl font-bold mt-6 mb-6 text-center overflow-auto">
						{shuffled.map((name, index) => (
							<div key={index}>{name}</div>
						))}
					</div>
				</> )}
			</div>
		</> }

		{ !isAuthenticated() && <>
			<div className="flex p-6">
				Neprihlásený!
			</div>
		</> }

	</>
}

export default MyClass;