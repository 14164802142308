import React, { useEffect } from 'react';
import Header from '../components/Header';
import { Link } from 'react-router-dom';

function Match() {
  return <>
    <Header active="match" />

    <div id="tiles">
        <Link id="match-penguins" className='link' to="/match/penguins">
            <span>Tučniaky</span>
        </Link>
        <Link id="match-words" className='link' to="/match/words">
            <span>Slová</span>
        </Link>
        <Link id="match-animals" className='link' to="/match/animals">
            <span>Čo jedia zvieratá</span>
        </Link>
        <Link id="match-shapes" className='link' to="/match/shapes">
            <span>Symetria</span>
        </Link>
        <Link id="match-chosen" className='link' to="/match/chosen">
            <span>Vybrané slová</span>
        </Link>
		<Link id="truefalse_teeth" className="link" to='/match/truefalse_teeth'>
        	<span>Zdravé Zuby</span>
      	</Link>
    </div>
  </>
}

export default Match;