import React, { KeyboardEvent, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../App';

interface HeaderProps {
    active: string
}

function Header({ active }: HeaderProps) {
    const userCtx = useContext(UserContext);
    const [showLogin, setShowLogin] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const loginUser = () => {
        const username = (document.querySelector("#username") as HTMLInputElement).value;
        const password = (document.querySelector("#password") as HTMLInputElement).value;
        userCtx.loginUser(username, password).then((success) => {
            setLoginError(!success);
            if (success) {
                setShowLogin(false);
            }
        });
    };

    const loginInputKeyDown = (e: KeyboardEvent<HTMLElement>) => {
        if (e.key === 'Enter') {
            loginUser();
        }
    };

    return <>
        <div id="nav" className='items-center hidden lg:flex'>
          <Link id="logo" to="/" className={active ? 'active' : ''}>Šikulík</Link>
          <Link id="info" className={'link ' + (active === 'info' ? 'active' : '')} to="/info">Informatika</Link>
	        <Link id="myclass" className={'link ' + (active === 'myclass' ? 'active' : '')} to="/myclass">Moja trieda</Link>
          {/* <Link id="maths" className={'link ' + (active === 'maths' ? 'active' : '')} to="/maths">Matematika</Link> */}
          <Link id="pexeso" className={'link ' + (active === 'pexeso' ? 'active' : '')} to="/pexeso">Pexeso</Link>
          <Link id="match" className={'link ' + (active === 'match' ? 'active' : '')} to="/match">Priraď</Link>
          {/* <Link id="xmas-ms" className={'link ' + (active === 'xmas-ms' ? 'active' : '')} to="/xmas-ms" reloadDocument>Vianočné úlohy MŠ</Link>
          <Link id="xmas-zs" className={'link ' + (active === 'xmas-zs' ? 'active' : '')} to="/xmas-zs" reloadDocument>Vianočné úlohy ZŠ</Link> */}

          {(active === 'info' || active == 'eval') && <>
              {userCtx.user == null &&
                <button id="login-btn" className='btn-primary mr-3' onClick={() => setShowLogin(true)}>Prihlásiť sa</button>
              }
              {userCtx.user != null &&
                <div className="ml-auto mr-3">
                  <span className='user-logged-in mr-3 ml-3'>{userCtx.user.fullName}</span>
                  <button id="logout-btn" className='btn-primary' onClick={userCtx.logoutUser}>Odhlásiť sa</button>
                </div>
              }
            </>
          }
        </div>
        <div id="nav" className='items-center lg:hidden flex relative'>
          <Link id="logo" to="/" className={active ? 'active' : ''}>Šikulík</Link>
          <div className='grow'></div>
          <div className='nav-button mr-3' onClick={() => setMenuOpen(!menuOpen)}>
            <button>MENU</button>
          </div>
          <div className={menuOpen ? 'flex absolute right-0 top-16 w-full items-center bg-amber-100 p-4 z-[200] flex-col' : 'hidden'} onClick={() => setMenuOpen(!menuOpen)}>
              <Link id="info" className={'link ' + (active === 'info' ? 'active' : '')} to="/info">Informatika</Link>
		          <Link id="myclass" className={'link ' + (active === 'myclass' ? 'active' : '')} to="/myclass">Moja trieda</Link>
              {/* <Link id="maths" className={'link ' + (active === 'maths' ? 'active' : '')} to="/maths">Matematika</Link> */}
              <Link id="pexeso" className={'link ' + (active === 'pexeso' ? 'active' : '')} to="/pexeso">Pexeso</Link>
              <Link id="match" className={'link ' + (active === 'match' ? 'active' : '')} to="/match">Priraď</Link>
              {/* <Link id="xmas-ms" className={'link ' + (active === 'xmas-ms' ? 'active' : '')} to="/xmas-ms" reloadDocument>Vianočné úlohy MŠ</Link>
              <Link id="xmas-zs" className={'link ' + (active === 'xmas-zs' ? 'active' : '')} to="/xmas-zs" reloadDocument>Vianočné úlohy ZŠ</Link> */}

              {(active === 'info' || active === 'eval') && <>
                  {userCtx.user == null &&
                    <button id="login-btn" className='btn-primary mr-3' onClick={() => setShowLogin(true)}>Prihlásiť sa</button>
                  }
                  {userCtx.user != null &&
                    <div className="ml-auto mr-3">
                    <span className='user-logged-in mr-3 ml-3'>{userCtx.user.fullName}</span>
                    <button id="logout-btn" className='btn-primary' onClick={userCtx.logoutUser}>Odhlásiť sa</button>
                    </div>
                  }
                </>
              }
          </div>
        </div>


        {showLogin ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-2xl font-semibold">
                    Prihlásenie
                  </h3>
                  <button className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowLogin(false)}>
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                
                <div className="relative p-6 flex-auto">
                    <label htmlFor="username" className="input-label">Meno</label>
                    <input type="text" id="username" className="input" autoFocus={true} onKeyDown={loginInputKeyDown} onInput={() => setLoginError(false)} />

                    <label htmlFor="password" className="input-label mt-2">Heslo</label>
                    <input type="password" id="password" className="input" onKeyDown={loginInputKeyDown} onInput={() => setLoginError(false)} />

                    {loginError &&
                        <div className="mt-5 text-red-500">
                            Chyba pri prihlásení!
                        </div>
                    }
                </div>
                
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button className="btn-secondary" type="button" onClick={() => setShowLogin(false)}>
                    Zavrieť
                  </button>
                  <button className="btn-primary ml-2" type="button" onClick={loginUser}>
                    Prihlásiť sa
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
}

export default Header;

/*
<div id="ulohy" class="link" onclick="window.location.href = 'ulohy.html';">Prázdninové úlohy</div>
*/